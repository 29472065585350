import { createContext, useEffect, useMemo, useState } from 'react';
import type { DialogProps as MuiDialogProps } from '@mui/material';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { logger } from '@noah-labs/shared-logger/browser';

export type TpDialog = {
  Content: React.ReactNode;
  DialogProps?: Omit<MuiDialogProps, 'open' | 'onClose'>;
  allowDismiss?: boolean;
  key: string;
  low?: boolean;
  onDismiss?: () => void;
  open: boolean;
  persistRouting?: boolean;
};

export type TpPushDialog = Omit<TpDialog, 'open'>;

/**
 * In this DialogsContext context, we provide all the notificatons and functions to remove / close them
 * This separation helps prevent unnecessary re-renders
 */
export type CxDialogs = {
  closeDialog: (key: string) => void;
  dialogs: Record<string, TpDialog>;
  pushDialog: (params: TpPushDialog) => void;
  removeDialog: (key: string) => void;
};
export const DialogsContext = createContext<undefined | CxDialogs>(undefined);

/**
 * Note that we always need to create a new array when updating the state, otherwise no re-renders!
 */
type PpDialogsProvider = PpWC & {
  history?: {
    listen(listener: () => void): () => void;
  };
};
export function DialogsProvider({ children, history }: PpDialogsProvider): React.ReactElement {
  const [dialogs, setDialogs] = useState<Record<string, TpDialog>>({});

  /**
   * Close all Dialogs on route changes, unless they have requested to be persisted, if we were given a listen function
   */
  useEffect(() => {
    if (!history) {
      return undefined;
    }
    const unlisten = history.listen(() => {
      setDialogs((prevDialogs) => {
        const updatedDialogs: Record<string, TpDialog> = prevDialogs;

        Object.keys(updatedDialogs).forEach((key) => {
          const dialog = updatedDialogs[key];

          logger.info('DialogsProvider / history.listen', {
            key,
            open: dialog.open,
            persistRouting: dialog.persistRouting,
          });
          dialog.open = dialog.persistRouting ? dialog.open : false;
        });

        return updatedDialogs;
      });
    });

    return (): void => unlisten();
  }, [history]);

  const memoedFunctions = useMemo(() => {
    /**
     * Add new dialog to the dialogs array and open immediately
     * Filtered to only display by unique key
     */
    function pushDialog({
      allowDismiss = true,
      Content,
      DialogProps,
      key,
      low = false,
      onDismiss,
      persistRouting = false,
    }: TpPushDialog): void {
      if (key in dialogs) {
        logger.info(key, 'in', JSON.stringify(dialogs));
        return;
      }

      logger.info('setDialogs', key);
      setDialogs((prev) => ({
        ...prev,
        [key]: {
          allowDismiss,
          Content,
          DialogProps,
          key,
          low,
          onDismiss,
          open: true,
          persistRouting,
        },
      }));
    }
    /**
     * Create a new dialogs array with open set to false on the target dialog.
     * Don't remove the dialog from the array here b/c we want the tranistions to complete.
     */
    function closeDialog(key: string): void {
      logger.info('closeDialog', key);
      setDialogs((prev) => ({
        ...prev,
        [key]: {
          ...prev[key],
          open: false,
        },
      }));
    }

    /**
     * Called when the transitions have finished, we can safely remove from the array
     */
    function removeDialog(key: string): void {
      logger.info('removeDialog', key);
      setDialogs((prevDialogs) => {
        const { [key]: deleted, ...newDialogs } = prevDialogs;
        return newDialogs;
      });
    }
    return {
      closeDialog,
      dialogs,
      pushDialog,
      removeDialog,
    };
  }, [dialogs]);

  return <DialogsContext.Provider value={memoedFunctions}>{children}</DialogsContext.Provider>;
}
