import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import type { PpWC } from '@noah-labs/fe-shared-ui-shared';
import { usePollReady, useRouter } from '@noah-labs/fe-shared-ui-shared';
import type { TpIntercomToggle } from './utils';
import { isIntercomBooted, shutdownIntercom, toggleIntercom } from './utils';

export type CxLiveChat = {
  loaded: boolean;
  // The attaching of the onClick event by the Intercom script is suffering from some race condition
  // that is difficult to solve because of us using Segment to load it and the fact,
  // that Segment currently has this bug: https://github.com/segmentio/action-destinations/pull/2660/files
  // So we will control the toggling of the chat more explicitly ourselves
  toggleChat: undefined | (() => void);
  unreadMessages: boolean;
};

export const LiveChatContext = createContext<CxLiveChat | undefined>(undefined);

function isLiveChatInFullScreenMode(): boolean {
  const el = document.getElementsByClassName('intercom-messenger-frame')[0];
  return window.getComputedStyle(el).getPropertyValue('max-height') === 'none';
}

type TpFunc = (() => void) | (() => Promise<void>);
type TpLiveChatProvider = PpWC & {
  addSignOutSubscriber: (name: string, sub: TpFunc) => void;
};
export function LiveChatProvider({
  addSignOutSubscriber,
  children,
}: TpLiveChatProvider): React.ReactElement {
  const [unreadMessages, setUnreadMessages] = useState(false);
  const [toggle, setToggle] = useState<TpIntercomToggle>('show');

  const { goForward } = useRouter();
  const handleBackButton = useCallback(() => {
    // see: https://github.com/noah-labs/noah-main/pull/5469
    toggleIntercom('hide');
    if (isLiveChatInFullScreenMode()) {
      goForward();
    }
  }, [goForward]);

  const intercomBooted = usePollReady(isIntercomBooted);

  useEffect(() => {
    if (!intercomBooted) {
      return;
    }

    window.Intercom('onShow', (): void => {
      // subscribe to reset the indicator when 'read'
      setUnreadMessages(false);
      setToggle('hide');
      window.addEventListener('popstate', handleBackButton);
    });

    window.Intercom('onHide', (): void => {
      setToggle('show');
      window.removeEventListener('popstate', handleBackButton);
    });

    // subscribe to new messages
    window.Intercom('onUnreadCountChange', (unreadCount): void => {
      setUnreadMessages(unreadCount > 0);
    });
  }, [handleBackButton, intercomBooted]);

  /**
   * subscribe to the signout callback and shutdown
   */
  useEffect(() => addSignOutSubscriber('ic', () => shutdownIntercom()), [addSignOutSubscriber]);

  const value = useMemo(
    () => ({
      loaded: intercomBooted,
      toggleChat: (): void => toggleIntercom(toggle),
      unreadMessages,
    }),
    [intercomBooted, toggle, unreadMessages],
  );

  return <LiveChatContext.Provider value={value}>{children}</LiveChatContext.Provider>;
}
