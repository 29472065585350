import { css } from '@emotion/react';
import { Badge } from '@mui/material';
import { NotificationIcon } from '@noah-labs/fe-shared-ui-assets/muiSvgIcons/NotificationIcon';
import { cssMixins } from '../theme/cssMixins';
import type { PpHeaderIconButton } from './HeaderIconButton';
import { HeaderIconButton } from './HeaderIconButton';

type PpNotificationCenterButton = {
  hasUnseenNotifications: boolean;
} & Pick<PpHeaderIconButton, 'edge' | 'onClick'>;

export function NotificationCenterButton({
  edge,
  hasUnseenNotifications,
  ...props
}: PpNotificationCenterButton): React.ReactElement {
  const styles = {
    iconContainer: css`
      position: relative;
      ${cssMixins.colCentered}
    `,
  };

  return (
    <HeaderIconButton aria-label="See Notifications" edge={edge} {...props}>
      <div css={styles.iconContainer}>
        <Badge
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          color="error"
          invisible={!hasUnseenNotifications}
          variant="dot"
        >
          <NotificationIcon />
        </Badge>
      </div>
    </HeaderIconButton>
  );
}
