import { Fragment } from 'react';
import { useRouter } from '@noah-labs/fe-shared-ui-shared';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { NoahHologram } from '../images/NoahHologram';
import { AppFooter } from '../layout/AppFooter';
import { AppMain } from '../layout/AppMain';
import { SceneImage } from '../scene/SceneImage';
import { SceneMain } from '../scene/SceneMain';
import { SceneParagraph, SceneSubTitle, SceneTitleLarge } from '../scene/Typography';

export type PpDisabledPage = {
  ActionButton?: React.ReactElement;
  description: string | React.ReactElement;
  title: string;
};

// A generic disabled feature page
export function DisabledPage({
  ActionButton,
  description,
  title,
}: PpDisabledPage): React.ReactElement {
  const { goBack } = useRouter();

  return (
    <Fragment>
      <AppMain sx={{ textAlign: 'center' }}>
        <SceneMain dataQa="disabled">
          <SceneImage alt="" src={NoahHologram} />
          <SceneTitleLarge>Sorry!</SceneTitleLarge>
          <SceneSubTitle>{title}</SceneSubTitle>
          <SceneParagraph>{description}</SceneParagraph>
        </SceneMain>
      </AppMain>
      <AppFooter>
        {ActionButton || (
          <PrimaryButton color="primaryBrand" data-qa="go-back" onClick={(): void => goBack()}>
            Back
          </PrimaryButton>
        )}
      </AppFooter>
    </Fragment>
  );
}
